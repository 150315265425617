

//Sumarry
//  1 - Modal menu Hamburger
//  2 - Header moviment
//  3 - Select Nav Item
//  4 - Animation


/***************************************************************/
// 1 - Modal menu hamburger
window.addEventListener('resize', function (event) {
    if (window.screen.width > 1150) {
        document.getElementById('modal-menu').classList.remove("active")
    }
})

const btnMenu = document.querySelector('.menu-hamburger')
const btnCloseMenu = document.querySelector(".close-menu")
const modalMenu = document.getElementById('modal-menu')
const navMenuBurger = document.querySelectorAll("#modal-menu li")


function openCloseMenu() {
    modalMenu.classList.toggle("active")
    document.body.classList.toggle("active")
    navMenuBurger.forEach(e => {
        e.classList.toggle("active-animation")
    })
}

btnMenu.addEventListener("click", openCloseMenu)
btnCloseMenu.addEventListener("click", openCloseMenu)

/************************************************************* */
//2 - Header moviment
const header = document.querySelector('#header-two')
const headerHeight = header.clientHeight * 4


let blockMenu = 0
window.addEventListener('resize', function (event) {
    if (window.screen.width < 1150) {
        blockMenu = 1
        header.classList.remove('active')
    }
})
window.addEventListener("scroll", ()=>{
    let position = document.body.getBoundingClientRect().top * -1
    if (position > headerHeight && blockMenu == 0) {
        header.classList.add('active')
        blockMenu = 2

    } else if (position < headerHeight && blockMenu == 2) {
        header.classList.remove('active')
        blockMenu = 0
    }
})

/*********************************** 3 - select nav item ****************************/
let homePage = document.querySelector('#home')
let productPage = document.querySelector('#products-category')
let businnesPage = document.querySelector('#company-page')
let contactPage = document.querySelector('#contact-page')
let buyPage = document.querySelector("#buy-page")
let newPage = document.querySelector('#news')

let query = location.search.slice(1);
let partes = query.split('?');
let resultUrl = partes[0]

function setSmoothScrollTo(endX, endY, duration) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== "undefined" ? duration : 400;

    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1)
            return (distance / 2) * time * time * time * time + from;
        return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = setInterval(() => {
        const time = new Date().getTime() - startTime;
        const newX = easeInOutQuart(time, startX, distanceX, duration);
        const newY = easeInOutQuart(time, startY, distanceY, duration);
        if (time >= duration) {
            clearInterval(timer);
        }
        window.scroll(newX, newY);
    }, 1000 / 60);
}

// if (homePage) {
//     let navItem = document.querySelectorAll(".nav-home")
//     navItem.forEach((e) => {
//         e.classList.add('active')
//     })
// }

// else 
if (productPage) {
    let navItem = document.querySelectorAll(".nav-products")
    navItem.forEach((e) => {
        e.classList.add('active')
    })
}
else if (businnesPage && resultUrl == '') {
    let navItem = document.querySelectorAll(".nav-business")
    navItem.forEach((e) => {
        e.classList.add('active')
    })
}
else if (resultUrl == 'quality') {
    let navItem = document.querySelectorAll(".nav-quality")
    navItem.forEach((e) => {
        e.classList.add('active')
    })
    
            let element = document.querySelector(`#${resultUrl}`)
            let distance = element.offsetTop - (header.clientHeight / 1.5)
            setTimeout(()=>{
                setSmoothScrollTo(0, distance, 3000)
            }, 1000)
    

}
else if (resultUrl == 'feedback') {
    let navItem = document.querySelectorAll(".nav-feedback")
    navItem.forEach((e) => {
        e.classList.add('active')
    })
    let element = document.querySelector(`#${resultUrl}`)
    let distance = element.offsetTop + 38
    setTimeout(()=>{
        setSmoothScrollTo(0, distance, 3000)
    }, 1000)
}

else if (contactPage) {
    let navItem = document.querySelectorAll(".nav-contact")
    navItem.forEach((e) => {
        e.classList.add('active')
    })
}

else if (buyPage) {
    let navItem = document.querySelectorAll(".nav-buy")
    navItem.forEach((e) => {
        e.classList.add('active')
    })
}

else if (newPage) {
    let navItem = document.querySelectorAll(".nav-news")
    navItem.forEach((e) => {
        e.classList.add('active')
    })
}



/*********************************** 4 - select nav item ****************************/
TweenMax.staggerFrom("#header li", 1, {
    y: -20,
    opacity: 0,
}, 0.1)
//////////////

let isCompanyPage = document.querySelector("#company-page")
if(isCompanyPage){
    let navItemBusiness = document.querySelectorAll(".nav-business")
    let navItemQuality = document.querySelectorAll(".nav-quality")
    let navItemFeedback = document.querySelectorAll(".nav-feedback")
    gsap.timeline({
        scrollTrigger: {
        trigger: "#feedback",
        start: "top",
        toggleActions: 'play none none none',
        onEnter: ()=>{
            navItemBusiness.forEach((e) => {
            e.classList.remove('active')
        })
    
        navItemQuality.forEach((e) => {
            e.classList.remove('active')
        })
    
        navItemFeedback.forEach((e) => {
            e.classList.add('active')
        })
        },
        onEnterBack: ()=>{
            navItemBusiness.forEach((e) => {
            e.classList.remove('active')
        })
    
        navItemQuality.forEach((e) => {
            e.classList.remove('active')
        })
    
        navItemFeedback.forEach((e) => {
            e.classList.add('active')
        })
        },
        onLeave: ()=>{
        
            navItemBusiness.forEach((e) => {
                e.classList.add('active')
            })
        
            navItemQuality.forEach((e) => {
                e.classList.remove('active')
            })
        
            navItemFeedback.forEach((e) => {
                e.classList.remove('active')
            })
            
        }
        },
    })

    gsap.timeline({
        scrollTrigger: {
        trigger: "#quality",
        start: "top 75%",
        toggleActions: "play none none none",
        onEnter: ()=>{
            navItemBusiness.forEach((e) => {
            e.classList.remove('active')
        })
    
        navItemQuality.forEach((e) => {
            e.classList.add('active')
        })
    
        navItemFeedback.forEach((e) => {
            e.classList.remove('active')
        })
        },
        onEnterBack: ()=>{
            navItemBusiness.forEach((e) => {
            e.classList.remove('active')
        })
    
        navItemQuality.forEach((e) => {
            e.classList.add('active')
        })
    
        navItemFeedback.forEach((e) => {
            e.classList.remove('active')
        })
        },
        onLeaveBack: ()=>{
            navItemBusiness.forEach((e) => {
                e.classList.add('active')
            })
        
            navItemQuality.forEach((e) => {
                e.classList.remove('active')
            })
        
            navItemFeedback.forEach((e) => {
                e.classList.remove('active')
            })
            
        }
        },
    })
}